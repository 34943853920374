.card .table th {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.table-responsive .table tr td {
  text-align: left !important;
}

.promote-admin-live-date {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.promote-admin-live-date > select:focus {
  outline: none;
}

@media screen and (max-width: 500px) {
  .promote-admin-live-date {
    flex-direction: column;
  }
  .tabs-outer {
    padding: 0px 5px !important;
  }
  .tabs-inner {
    font-size: 9px !important;
    font-weight: 600 !important;
    padding: 10px 0px !important;
    white-space: nowrap;
  }
}
