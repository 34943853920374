/* CSS for hiding elements */
.hide-sidenav {
  display: none;
}

.show-skeleton {
  display: block;
}

/* CSS for showing elements */
.show-sidenav {
  display: block;
}

.hide-skeleton {
  display: none;
}

#sidenav-main {
  overflow: auto;
}

#sidenav-main::-webkit-scrollbar {
  height: 10px;
  width: 3px;
}

#sidenav-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #575f6d;
}

.modal-open {
  overflow: hidden; /* Prevent scrolling */
}
