.MuiAvatarGroup-root .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
  z-index: 100 !important;
}

.recharts-wrapper {
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0px 20px 0px 0px;
}

.withdrawl-input {
  border-bottom: 1.7px dotted #000;
  border-top: none;
  border-right: none;
  border-left: none;
}

.withdrawl-input:focus,
.withdrawl-input:focus {
  outline: none;
}

.date-icon {
  background-color: #efefef;
  padding: 2px 7px;
  margin-right: 5px;
  border-radius: 4px;
}

.stats-item {
  padding-left: 30px;
}

.border-left {
  border-left: 1px solid #d9d9d9;
}

.stat-value {
  font-size: 26px;
  font-weight: 400;
  color: #000;
}

.stat-label {
  font-size: 16px;
  font-weight: 600;
}

.no-scroll {
  overflow: hidden;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderr {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderr:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderr {
  background-color: #525f7f;
}

input:checked + .sliderr:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

.sliderr.round {
  border-radius: 34px;
}

.sliderr.round:before {
  border-radius: 50%;
}

.status-custom-dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding-right: 1rem;
  outline: none;
  width: 100%;
}

.status-custom-dropdown:focus {
  outline: none;
  box-shadow: none;
}

.status-custom-dropdown option {
  text-align: center;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  padding: 10px;
}
