.dot-pulse {
  position: relative;
  left: -9999px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  color: white;
}
.dot-pulse::before {
  box-shadow: 9974px 0 0 -10px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10024px 0 0 -10px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}
@keyframes dot-pulse-before {
  0% {
    box-shadow: 9974px 0 0 -10px;
  }
  30% {
    box-shadow: 9974px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9974px 0 0 -10px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -10px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -10px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10024px 0 0 -10px;
  }
  30% {
    box-shadow: 10024px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10024px 0 0 -10px;
  }
}
