.outer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 5px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/* Hide by default */
.outer-container {
  display: none;
}

/* Show only on mobile devices */
@media only screen and (max-width: 835px) {
  .outer-container {
    display: flex;
  }
}

.logoContainer {
  flex: 0 0 auto;
}

.logo {
  width: 40px;
  height: 40px;
}

.app-store-logo {
  height: 50px;
  width: 120px;
  margin-left: 30px;
}

.play-store-logo {
  height: 45px;
  width: 120px;
  margin-left: 30px;
}

.textContainer {
  flex: 1 1 auto;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.get-app-btn {
  background-color: #f7b82c;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.closeButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}
