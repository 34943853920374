.clear-btn {
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 75px;
    z-index: 99999;
    border: none;
    top: 12px;
    height: 30px;
    cursor: pointer;
    color: #000;
    background-color: transparent;
    transform: translateX(2px);
}