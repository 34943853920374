.not-found-div,
.error-403-div,
.server-error-div {
  font-family: sans-serif;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 30px;
}

.not-found-img,
.server-error-img {
  max-width: 250px;
}
.error-403-img{
  max-width: 400px;
}

.not-found-h1,
.error-403-h1,
.server-error-h1 {
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  color: #32325d;
  padding-top: 30px;
  font-size: 30px;
}

.not-found-p,
.error-403-p,
.server-error-p {
  color: #525f7f;
  padding-bottom: 20px;
  max-width: 600px;
}
.server-error-button-home,
.error-403-button-home,
.not-found-button-home {
  color: #111e37;
  border-color: #b3e1f8;
  background-color: #b3e1f8;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  letter-spacing: 0.025em;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 5px;
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .server-error-button-home:hover,
  .error-403-button-home:hover,
  .not-found-button-home:hover {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
}

@media (max-width: 767px) {
  .not-found-img,
  .server-error-img {
    max-width: 200px;
  }

  .error-403-img{
    max-width: 300px;
  }

  .not-found-h1,
  .error-403-h1,
  .server-error-h1 {
    padding-top: 25px;
    font-size: 20px;
  }
  .not-found-p,
  .error-403-p,
  .server-error-p {
    padding-bottom: 15px;
    font-size: 15px;
  }
}
